<template>
  <div class="all">
    <van-nav-bar title="周公解梦" left-arrow @click-left="onClickLeft" />
    <van-search
      v-model="searchValue"
      shape="round"
      background="#fcfcfc"
      @input="inputSearch"
      placeholder="请输入搜索关键词"
    />
    <div v-if="!searchValue" class="container">
      <div class="dream-kind">
        <div class="kind-title">
          <span style="font-size: 16px">解梦分类</span>
          <span style="color: #9e9c9c">了解更多请搜索关键字</span>
        </div>
      </div>
      <div class="people" v-for="(item, index) in dreamKindData" :key="index">
        <div class="people-title">
          <span style="font-size: 16px; font-weight: bold">{{
            item.name
          }}</span>
          <span></span>
        </div>
        <div class="all-people">
          <div
            class="single-people"
            v-for="(item2, index2) in item.secondLevel"
            :key="index2"
            @click="recommendItem(item2.secondName)"
          >
            <span>{{ item2.secondName }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 搜索结果 -->
    <div v-else class="container2">
      <div class="all-result">
        <div class="single-result">
          <div
            v-for="(item, index) in searchList"
            :key="index"
            style="margin-bottom: 10px"
          >
            <span class="result-title" @click="searchMore(item)"
              >{{item.title}}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { throttle } from "@/router/utils";
import { getDreamKind, getSearchList } from "@/api/sovleDream";
export default {
  data() {
    return {
      searchValue: "",
      // 高度
      height2: 170 + "px",
      isOpen: "展开",
      dreamKindData: [],
      dreamKindList: [],
      searchList: []
    };
  },
  created() {
    this.$store.commit("setSession", this.$route.query.session)
    this.getDreamKind();
  },
  methods: {
    // 获取最上面梦的类型
    getDreamKind() {
      getDreamKind({ fid: "0" }).then((res) => {
        if (res.data.code === 0) {
          console.log(res, "res");
          this.dreamKindData = res.data.data.result;
          this.dreamKindData.map((item) => {
            item.secondLevel = [];
          });

          this.dreamKindData[0].secondLevel = [
            {
              secondName: "男人",
            },
            {
              secondName: "女人",
            },
            {
              secondName: "父亲",
            },
            {
              secondName: "朋友",
            },
            {
              secondName: "老公",
            },
            {
              secondName: "少女",
            },
            {
              secondName: "母亲",
            },
            {
              secondName: "婴儿",
            },
            {
              secondName: "情人异性",
            },
          ];
          this.dreamKindData[1].secondLevel = [
            {
              secondName: "被猫抓",
            },
            {
              secondName: "家里长草",
            },
            {
              secondName: "泡芙",
            },
            {
              secondName: "政治变革",
            },
            {
              secondName: "尾巴",
            },
            {
              secondName: "自己缩小",
            },
            {
              secondName: "喘息",
            },
            {
              secondName: "破坏",
            },
            {
              secondName: "钟声",
            },
          ];
          this.dreamKindData[2].secondLevel = [
            {
              secondName: "鱼",
            },
            {
              secondName: "蛇",
            },
            {
              secondName: "狗",
            },
            {
              secondName: "猫",
            },
            {
              secondName: "猪",
            },
            {
              secondName: "乌龟",
            },
            {
              secondName: "壁虎",
            },
            {
              secondName: "牛",
            },
            {
              secondName: "马",
            },
          ];
          this.dreamKindData[3].secondLevel = [
            {
              secondName: "玩牌",
            },
            {
              secondName: "音乐",
            },
            {
              secondName: "庆典",
            },
            {
              secondName: "宴席",
            },
            {
              secondName: "玩耍",
            },
            {
              secondName: "唱戏",
            },
            {
              secondName: "跳舞",
            },
            {
              secondName: "购物",
            },
            {
              secondName: "电影",
            },
          ];
          this.dreamKindData[4].secondLevel = [
            {
              secondName: "耶稣基督",
            },
            {
              secondName: "朝圣",
            },
            {
              secondName: "教会",
            },
            {
              secondName: "圣经",
            },
            {
              secondName: "伊旬园",
            },
            {
              secondName: "上帝",
            },
            {
              secondName: "天堂",
            },
            {
              secondName: "祭台",
            },
            {
              secondName: "忏悔",
            },
          ];
          this.dreamKindData[5].secondLevel = [
            {
              secondName: "大河",
            },
            {
              secondName: "海洋",
            },
            {
              secondName: "溪流",
            },
            {
              secondName: "高山",
            },
            {
              secondName: "大海",
            },
            {
              secondName: "破布",
            },
            {
              secondName: "荒野",
            },
            {
              secondName: "田野",
            },
            {
              secondName: "森林",
            },
          ];
          this.dreamKindData[6].secondLevel = [
            {
              secondName: "高楼",
            },
            {
              secondName: "屋顶",
            },
            {
              secondName: "水井",
            },
            {
              secondName: "厕所",
            },
            {
              secondName: "监狱牢狱",
            },
            {
              secondName: "立交桥",
            },
            {
              secondName: "儿童乐园",
            },
            {
              secondName: "加油站",
            },
            {
              secondName: "宾馆",
            },
          ];
          this.dreamKindData[7].secondLevel = [
            {
              secondName: "灵车",
            },
            {
              secondName: "办丧事",
            },
            {
              secondName: "烧纸",
            },
            {
              secondName: "火葬",
            },
            {
              secondName: "魔鬼",
            },
            {
              secondName: "祭台",
            },
            {
              secondName: "挖坟",
            },
            {
              secondName: "挖人",
            },
            {
              secondName: "鬼屋",
            },
          ];
          this.dreamKindData[8].secondLevel = [
            {
              secondName: "分手",
            },
            {
              secondName: "伤心",
            },
            {
              secondName: "相亲",
            },
            {
              secondName: "约会",
            },
            {
              secondName: "一见钟情",
            },
            {
              secondName: "初恋情人",
            },
            {
              secondName: "亲吻",
            },
            {
              secondName: "结婚",
            },
            {
              secondName: "离婚",
            },
          ];
          this.dreamKindData[9].secondLevel = [
            {
              secondName: "鲜花",
            },
            {
              secondName: "竹子",
            },
            {
              secondName: "灵芝",
            },
            {
              secondName: "葡萄树",
            },
            {
              secondName: "大麦",
            },
            {
              secondName: "青草",
            },
            {
              secondName: "仙人掌",
            },
            {
              secondName: "榆树",
            },
            {
              secondName: "松柏",
            },
          ];
          this.dreamKindData[10].secondLevel = [
            {
              secondName: "考试",
            },
            {
              secondName: "修路",
            },
            {
              secondName: "赶集",
            },
            {
              secondName: "摘苹果",
            },
            {
              secondName: "劳动",
            },
            {
              secondName: "种地",
            },
            {
              secondName: "插秧",
            },
            {
              secondName: "坐火车",
            },
            {
              secondName: "爬行",
            },
          ];
          this.dreamKindData[11].secondLevel = [
            {
              secondName: "大便",
            },
            {
              secondName: "棺材",
            },
            {
              secondName: "钞票",
            },
            {
              secondName: "汽车",
            },
            {
              secondName: "黄金",
            },
            {
              secondName: "衣服",
            },
            {
              secondName: "自行车",
            },
            {
              secondName: "鞋",
            },
            {
              secondName: "手机",
            },
          ];
          this.dreamKindData[12].secondLevel = [
            {
              secondName: "怀孕",
            },
            {
              secondName: "读书",
            },
            {
              secondName: "上厕所",
            },
            {
              secondName: "洗头发",
            },
            {
              secondName: "吃饭",
            },
            {
              secondName: "睡觉",
            },
            {
              secondName: "打电话",
            },
            {
              secondName: "吵架",
            },
            {
              secondName: "做饭",
            },
          ];
          this.dreamKindData[13].secondLevel = [
            {
              secondName: "生病",
            },
            {
              secondName: "注射",
            },
            {
              secondName: "打针",
            },
            {
              secondName: "拔牙",
            },
            {
              secondName: "看病",
            },
            {
              secondName: "流鼻血",
            },
            {
              secondName: "分娩",
            },
            {
              secondName: "流产",
            },
            {
              secondName: "呕吐",
            },
          ];
          this.dreamKindData[14].secondLevel = [
            {
              secondName: "发大水",
            },
            {
              secondName: "冰雹",
            },
            {
              secondName: "暴风雨",
            },
            {
              secondName: "龙卷风",
            },
            {
              secondName: "地震",
            },
            {
              secondName: "被雷劈",
            },
            {
              secondName: "暴雨",
            },
            {
              secondName: "海啸",
            },
            {
              secondName: "彩虹",
            },
          ];
          this.dreamKindData[15].secondLevel = [
            {
              secondName: "脸",
            },
            {
              secondName: "腹部",
            },
            {
              secondName: "嘴巴",
            },
            {
              secondName: "腿",
            },
            {
              secondName: "断胳",
            },
            {
              secondName: "嘴唇",
            },
            {
              secondName: "骨头",
            },
            {
              secondName: "指甲",
            },
            {
              secondName: "眼睛",
            },
          ];
          this.dreamKindData[16].secondLevel = [
            {
              secondName: "打篮球",
            },
            {
              secondName: "摔跤",
            },
            {
              secondName: "射击",
            },
            {
              secondName: "攀登",
            },
            {
              secondName: "跑步",
            },
            {
              secondName: "赛跑",
            },
            {
              secondName: "比赛",
            },
            {
              secondName: "踢球",
            },
            {
              secondName: "骑马",
            },
          ];
          this.dreamKindData[17].secondLevel = [
            {
              secondName: "葡萄",
            },
            {
              secondName: "水蜜桃",
            },
            {
              secondName: "黄瓜",
            },
            {
              secondName: "苹果",
            },
            {
              secondName: "花生",
            },
            {
              secondName: "红薯",
            },
            {
              secondName: "西红柿",
            },
            {
              secondName: "海带",
            },
            {
              secondName: "荔枝",
            },
          ];
          this.dreamKindData[18].secondLevel = [
            {
              secondName: "鬼",
            },
            {
              secondName: "观音菩萨",
            },
            {
              secondName: "自己会飞",
            },
            {
              secondName: "绿鬼",
            },
            {
              secondName: "财神爷",
            },
            {
              secondName: "佛祖",
            },
            {
              secondName: "妖怪",
            },
            {
              secondName: "轮回",
            },
            {
              secondName: "看卦",
            },
          ];
          let lastArr = this.dreamKindData[1];
          this.dreamKindData.splice(1, 1);
          this.dreamKindData.push(lastArr);
        }
      });
    },
    // 查看更多
    searchMore(item) {
      this.$router.push({
        path: "/solveDreamKind",
        query: {
          id: item.id,
        },
      });
    },
    // 点击推荐
    recommendItem(item) {
      this.searchValue = item
      console.log(item);
       getSearchList({q: item}).then((res) => {
        if (res.data.code === 0) {
          console.log(1111);
           this.searchList = res.data.data.result
        }
      });
    },
    //   点击展开
    open() {
      if (this.height2 == 170 + "px") {
        this.height2 = 100 + "%";
        this.isOpen = "收起";
      } else {
        this.height2 = 170 + "px";
        this.isOpen = "展开";
      }
    },
    inputSearch: throttle(function (val) {
      // let formData = new window.FormData();
      // formData.append("q", val);
      // console.log(val, "11");
      let params = {
        q: val,
        // cid: 0,
        // full: 0
      }
      getSearchList(params).then((res) => {
        if (res.data.code === 0) {
           this.searchList = res.data.data.result
        }
      });
    }, 1000),
    onClickLeft() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.close.postMessage("up");
      }
      if (isAndroid) {
        window.android.close();
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .van-nav-bar {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;
}
/deep/ .van-nav-bar {
  background-color: #f1f1f1;
}
.all {
  background-color: #9e9c9c;
  background-color: #f1f1f1;
  height: calc(100vh -50px);
  overflow-x: hidden;
  .container {
    box-sizing: border-box;
    padding: 0 15px;
    .dream-kind {
      .kind-title {
        margin: 15px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }
      .imgs {
        display: flex;
        flex-wrap: wrap;
        width: 110%;
        overflow: hidden;
        .img {
          border-radius: 10px;
          border: 1px solid pink;
          width: 75px;
          height: 75px;
          background-image: url("../../assets/img/编组 6.png");
          background-size: 40px 40px;
          margin-bottom: 10px;
          margin-right: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          // .img-name {
          //   margin: 0 auto;
          //   text-align: center;
          // }
        }
      }
    }
    .people {
      margin-top: 15px;
      .people-title {
        height: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .all-people {
        display: flex;
        flex-wrap: wrap;
        width: 110%;
        margin-top: 15px;
        .single-people {
          width: 100px;
          height: 30px;
          margin-right: 22px;
          margin-bottom: 10px;
          border-radius: 20px;
          // #fcfcfc   #f1f1f1
          background-color: #fcfcfc;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .container2 {
    height: 100vh;
    box-sizing: border-box;
    padding: 0 15px;
    .all-result {
      margin-top: 10px;
      .single-result {
        width: 100%;
        margin-bottom: 15px;
        background-color: #f4f7fa;
        border-radius: 20px;
        box-sizing: border-box;
        padding: 15px;
        .result-title {
          font-size: 16px;
          // font-weight: bold;
          margin-left: 5px;
        }
        .result-content {
          display: inline-block;
          margin-top: 13px;
          margin-left: 5px;
        }
      }
    }
  }
}
/deep/ .van-search {
  background-color: #f1f1f1 !important;
  margin-top: 50px;
}
</style>