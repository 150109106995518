// 函数防抖
export function debounce (fn, delay) {
    let timer = null
    return function () {
      const content = this
      const args = arguments
      if (timer) {
        clearTimeout(timer)
      }
      timer = setTimeout(() => {
        fn.apply(content, args)
      }, delay)
    }
  }
  /**
   * @desc 函数节流
   * 触发函数立即执行， 但在n秒内连续触发则不执行
   * @param func 函数
   * @param wait 延迟执行的毫秒数
   */
  export function throttle (func, wait) {
    let timer
    return function () {
      if (timer != null) return
      const _this = this
      const args = arguments
      func.apply(_this, args)
      timer = setTimeout(() => {
        timer = null
      }, wait)
    }
  }
  